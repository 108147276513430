import React, { useEffect, useState, useContext } from 'react';
import { Form, Row, Col } from 'antd';
import { BInput } from 'common/components';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { readOneClientRequest, readOneClientClean } from '../redux-saga/actions';
import getRootModule from 'RootModule';
import { Manager, Signer, SignerBASF, Client, SalesInfo, Contact } from '../components';
import clientModule from '../clientModule';
import { ActionButtons, BDivider } from 'common';
import { useHistory, useParams } from 'react-router-dom';
import { layoutContext } from 'context';

const GetClient = (props) => {
  const {
    clientData,
    readOneClientRequest: readOneClientReq,
    readOneClientClean: clientClean,
  } = props;

  const [showInputs, setShowInputs] = useState({
    agreementRequirement: false,
    imageFile: false,
  });

  const { onChangeActiveComponent } = useContext(layoutContext);
  const [form] = Form.useForm();
  const [clientSign, setclientSign] = useState(null);
  const [clientSignBASF, setclientSignBASF] = useState(null);
  const [clientManag, setclientManag] = useState(null);
  let numSignerBASF = 0;

  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    checked: false,
    disabled: false,
  });
  const params = useParams();
  const history = useHistory();
  const { state } = history.location;
  const userId = params.id;

  useEffect(() => {
    readOneClientReq({ clientId: userId });
    onChangeActiveComponent('Users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClientDataForm = () => {
    if (clientData?.agreementRequirement !== 0 && clientData?.imagen) {
      setShowInputs({ imageFile: true, agreementRequirement: true });
    } else if (clientData?.agreementRequirement !== 0) {
      setShowInputs({ agreementRequirement: true });
    }
    form.setFieldsValue({
      soldTo: clientData?.soldTo,
      name: clientData?.name,
      timeLimit: clientData?.timeLimit,
      creditLimit: formatThous(clientData?.creditLimit),
      presence: clientData?.presence,
      type: clientData?.type,
      line: clientData?.line,
      segmentation: clientData?.segmentation,
      tradeAgreementRequired: clientData?.tradeAgreementRequired ? 'Si' : 'No',
      agreementRequirement: clientData?.agreementRequirement,
      imageFile: clientData?.imagen?.fileName,
      address: clientData?.address,
      company: clientData?.company,
      owner: clientData?.owner,
      territory: clientData?.clientManagers?.territory,
      //clientManagers
      clientManagers: clientData?.clientManagers,
      //clientSigners
      clientSigners: clientData?.clientSigners,
      //contact
      contactFullName: clientData?.contact?.fullName,
      contactDepartment: clientData?.contact?.department,
      contactEmail: clientData?.contact?.email,
      //salesInfo
      branchesCount: clientData?.salesInfo?.branchesCount
        ? formatThous(clientData?.salesInfo?.branchesCount)
        : '',
      clientsCount: clientData?.salesInfo?.clientsCount
        ? formatThous(clientData?.salesInfo?.clientsCount)
        : '',
      salesForceCount: clientData?.salesInfo?.salesForceCount
        ? formatThous(clientData?.salesInfo?.salesForceCount)
        : '',
      techRepCount: clientData?.salesInfo?.techRepCount
        ? formatThous(clientData?.salesInfo?.techRepCount)
        : '',
      totalYearIncome: clientData?.salesInfo?.totalYearIncome
        ? formatThous(clientData?.salesInfo?.totalYearIncome)
        : '',
      invoicingCurrency: clientData?.salesInfo?.invoicingCurrency,
      agreementCurrency: clientData?.salesInfo?.agreementCurrency,
      incoterm: clientData?.salesInfo?.incoterm,
      pogType: clientData?.salesInfo?.pogType,
      active: clientData?.salesInfo?.active,
      //Account Statement Contact
    });
  };

  useEffect(() => {
    if (clientData) {
      setStatusSwitch({
        disabled: true,
        checked: !clientData?.enabled,
      });
      setclientSign(clientData?.clientSigners ? clientData?.clientSigners : '');
      setclientSignBASF(clientData?.basfSigners ? clientData?.basfSigners : '');
      setclientManag(clientData?.clientManagers ? clientData?.clientManagers : '');
      //Load Initial Safe Values
      handleClientDataForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, form]);

  //Thousands separator with ","
  const formatThous = (num) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const onCancel = () => {
    history.push('/catalog/clients');
  };

  const goToEditPage = () => {
    clientClean();

    history.push({
      pathname: `/catalog/clients/update/${userId}`,
      state: { ...state },
    });
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange>
          <BDivider title={'Información de Cliente'} />

          <Client showInputs={showInputs} />

          <BDivider title={'Información de responsable'} />

          {clientManag ? (
            clientManag.map((clientManager) => (
              <Manager
                key={clientManager.userId}
                fullName={clientManager.fullName}
                territory={clientManager.territory}
              />
            ))
          ) : (
            <Manager />
          )}

          <BDivider title={'Información del representante'} />

          <Row>
            <Col span={8}>
              <BInput
                disabled
                isRequired
                className="form-add-user-input"
                name="owner"
                label="Nombre del propietario o gerente:"
              />
            </Col>
          </Row>

          <BDivider title={'Información de firmantes cliente'} />

          {clientSign ? (
            clientSign.map((signer) => (
              <Signer key={signer.id} fullName={signer.fullName} position={signer.position} />
            ))
          ) : (
            <Signer />
          )}

          <BDivider title={'Información de firmantes BASF'} />

          {clientSignBASF ? (
            clientSignBASF.map((signerBASF) => {
              return (
                <SignerBASF
                  numSignerBASF={numSignerBASF++}
                  signerBASF={signerBASF}
                  key={signerBASF.fullName}
                  fullName={signerBASF.fullName}
                  position={signerBASF.position}
                />
              );
            })
          ) : (
            <SignerBASF />
          )}

          <BDivider title={'Información de contacto comercial del cliente'} />

          <Contact />

          <BDivider title={'Información de Ventas'} />

          <SalesInfo />
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        goToEditPage={goToEditPage}
        statusSwitch={statusSwitch}
        /*   onStatusUpdate={handleStatusUpdate} */
        disabled={false}
        buttons={['cancel', 'goToEditPage']}
      />
    </>
  );
};

function mapStateToProps(state) {
  return { clientData: state?.CLIENT?.client?.data };
}

const ConnectedGetClientForm = connect(mapStateToProps, {
  readOneClientRequest,
  readOneClientClean,
})(GetClient);

const DetailClient = () => (
  <DynamicModuleLoader modules={[getRootModule(), clientModule()]}>
    <ConnectedGetClientForm />
  </DynamicModuleLoader>
);

export default DetailClient;
